import { useState } from "react";
import { TextField, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import configs from "../../Constants";

export default function EditableField({
  label,
  value,
  type = "text",
  fieldKey,
  userInfo,
  setUserInfo,
  isEditable = true, 
  width = "250px"
}) {
  const [editMode, setEditMode] = useState(false);
  const [fieldValue, setFieldValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const baseURL = configs.baseURL;

  const handleSave = async () => {
    if (!userInfo) {
      console.error("User info is undefined");
      return;
    }

    setLoading(true);

    try {
      // Optimistically update state before API call
      setUserInfo((prev) => ({
        ...prev,
        [fieldKey]: fieldValue,
      }));

      // API request to update the backend
      await axios.put(`${baseURL}/api/settings/${userInfo.id}`, {
        ...userInfo,
        [fieldKey]: fieldValue, // Update only the modified field
      });

      console.log("Field updated successfully!");

      setEditMode(false);
    } catch (error) {
      console.error("Error updating field:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setFieldValue(value);
    setEditMode(false);
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
      <label style={{ fontWeight: "bold", width: "40%" }}>{label}:</label>
      {editMode ? (
        <TextField
          size="small"
          type={type}
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
          disabled={loading}
          style={{width}}
        />
      ) : type === "color" ? (
        // If type is "color", show a color box instead of text
        <div
          style={{
            width: "40px",
            height: "25px",
            backgroundColor: fieldValue || "#000000",
            border: "1px solid #ccc",
            borderRadius: "4px",
            cursor : "pointer"
          }}
        ></div>) : (
        <span style={{width : "20%" , height:"25px" , overflow:"hidden"}}>{fieldValue || "N/A"}</span>
      )}
      <div>
        {isEditable ? (
          editMode ? (
            <>
              <IconButton onClick={handleSave} disabled={loading}>
                <CheckIcon color="success" />
              </IconButton>
              <IconButton onClick={handleCancel} disabled={loading}>
                <CloseIcon color="error" />
              </IconButton>
            </>
          ) : (
            <IconButton onClick={() => setEditMode(true)}>
              <EditIcon />
            </IconButton>
          )
        ) : null} {/* Hide edit icon if field is not editable */}
      </div>
    </div>
  );
}
